import React, {useState} from 'react'
import { useBarcode } from 'react-barcodes'
import Moment from 'react-moment'
import './sscc.css'
import recycleImage from '../assets/images/sscc/recycle.png'
const SSCC = () => {

    const [sku, setSku] = useState(' ');
    const [upc, setUpc] = useState('03600291452');
    const [qty, setQty] = useState(' ');
    const [sscc, setSscc] = useState(' ');
    const [manufactureDate, setManufactureDate] = useState(' ');
    const [customerPo, setCustomerPo] = useState(' ');
    const [madeIn, setMadeIn] = useState('Korea');
    const [country, setCountry] = useState('US');
    function isValidBarcode(value) {
        // We only allow correct length barcodes
        if (!value.match(/^(\d{8}|\d{12,14})$/)) {
          return false;
        }
      
        const paddedValue = value.padStart(14, '0');
      
        let result = 0;
        for (let i = 0; i < paddedValue.length - 1; i += 1) {
          result += parseInt(paddedValue.charAt(i), 10) * ((i % 2 === 0) ? 3 : 1);
        }
      
        return ((10 - (result % 10)) % 10) === parseInt(paddedValue.charAt(13), 10);
      }

    const skuRef = useBarcode({
        value: sku,
        options: {
          format:'code128',
          text:'SKU:'+sku,
          height:100
        }
      }).inputRef;
      const upcRef = useBarcode({
        value: upc,
        options: {
          format:'upc',
          displayValue:true,
          height:80
        }
      }).inputRef;
      const qtyRef = useBarcode({
        value: qty,
        options: {
          format:'code128',
          text:'QTY/CARTON: '+qty
        }
      }).inputRef;
      const ssccRef = useBarcode({
        value: sscc,
        options: {
          format:'code128',
          text:'Carton ID (SSCC): '+sscc
        }
      }).inputRef;
    return (
        <div className="m-4">
        <div className="row">
            <div className="col-5" id='noprint'>
            <div className="row">
            <div className="col">
            <label>
            SKU: 
                <input type="text" name="sku" onChange={e => setSku(e.target.value ? e.target.value : ' ')}/>
            </label>
            </div>
            </div>
            <div className="row">
            <div className="col">
            <label>
            UPC: 
                <input type="text" name="upc" onChange={e => setUpc(isValidBarcode(e.target.value) ? e.target.value : '03600291452')}/>
                { isValidBarcode(upc) ? '' : <font color='red'>invalid upc</font>}
            </label>
            </div>
            </div>
            <div className="row">
            <div className="col">
            <label>
            Quantity: 
                <input type="text" name="qty" onChange={e => setQty(e.target.value ? e.target.value : ' ')}/>
            </label>
            </div>
            </div>
            <div className="row">
            <div className="col">
            <label>
            SSCC: 
                <input type="text" name="sscc" onChange={e => setSscc(e.target.value ? e.target.value : ' ')}/>
            </label>
            </div>
            </div>
            <div className="row">
            <div className="col">
            <label>
            Manufacture Date: 
                <input type="date" name="manufactureDate" onChange={e => setManufactureDate(e.target.value ? e.target.value : ' ')}/>
            </label>
            </div>
            </div>
            <div className="row">
            <div className="col">
            <label>
            Customer PO: 
                <input type="text" name="customerPo" onChange={e => setCustomerPo(e.target.value ? e.target.value : ' ')}/>
            </label>
            </div>
            </div>
            <div className="row">
            <div className="col">
            <label>
            Made in: 
                <input type="text" name="madeIn" onChange={e => setMadeIn(e.target.value ? e.target.value : ' ')}/>
            </label>
            </div>
            </div>
            <div className="row">
            <div className="col">
            <label>
            Destination Country: 
                <input type="text" name="country" onChange={e => setCountry(e.target.value ? e.target.value : ' ')}/>
            </label>
            </div>
            </div>
            </div>
            <div className="col-7 p-3">
            <div className="row border border-dark">
            <div className="col-6 p-2 border border-dark d-flex justify-content-center">
            <svg ref={skuRef} />
            </div>
            <div className="col-6 p-2 border border-dark d-flex justify-content-center">
            <svg ref={upcRef} />
            </div>
            </div>
            <div className="row border border-dark">
            <div className="col p-2 border border-dark d-flex justify-content-center">
            <svg ref={qtyRef} />
            </div>
            </div>
            <div className="row border border-dark">
            <div className="col border border-dark d-flex justify-content-center">
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div>
            </div>
            <div className="row border border-dark">
            <div className="col p-2 border border-dark d-flex justify-content-center">
            <svg ref={ssccRef} />
            </div>
            </div>
            <div className="row border border-dark">
            <div className="col p-3 border border-dark d-flex justify-content-center" style={{font:"20px monospace"}}>
            Manufacture Date:&nbsp;<Moment format="MM/DD/YYYY">{manufactureDate}</Moment>
            </div>
            </div>
            <div className="row border border-dark">
            <div className="col-8 p-3 border border-dark d-flex justify-content-center" style={{font:"20px monospace"}}>
            Customer PO:&nbsp;{customerPo}
            </div>
            <div className="col-4 p-3 border border-dark d-flex justify-content-center" style={{font:"20px monospace"}}>
            Made in&nbsp;{madeIn}
            </div>
            </div>
            <div className="row border border-dark">
            <div className="col-8 p-3 border border-dark d-flex justify-content-center" style={{font:"20px monospace"}}>
            
            </div>
            <div className="col-4">
            <div className="row border border-dark">
            <div className="col p-3 border border-dark d-flex justify-content-center" style={{font:"20px monospace"}}>
            <img src={recycleImage}></img>
            </div>
            </div>
            <div className="row border border-dark">
            <div className="col p-3 border border-dark d-flex justify-content-center" style={{font:"20px monospace"}}>
            {country}
            </div>
            </div>
            </div>
            </div>
            </div>
            <div className="col-1"> </div>
        </div>
        </div>
    );
}

export default SSCC